import React from "react"
import Header from '../components/Header'
import Navigation from '../components/Navigation'

import Bottom from "../components/Bottom"

import Contact from "../components/Contact"
import HeroContact from "../components/HeroContact"





export default function contact() {



  return (
    <>
      <Header />
      <Navigation />
      <HeroContact/>
      <Bottom/>
      
     
      {/* <Hero/> */}
      
      <Contact/>
      <iframe title="Contact" frameborder="0" style={{height:"550px", width:"99%", border:"none",}} src='https://forms.zohopublic.com/xccelerata1/form/QuestionInMind/formperma/OnZa0Nx14WXfHZ3nnJEelE-PIyj7JDftnV9OZsEiNGc'></iframe>

      <main id="main">


      </main>


    </>
  )
}